import * as AuthConstants from 'auth/constants'
import { isString } from 'lodash'
import { LOGOUT_BASE_ROUTE } from 'pages/logout/constants'
import * as Constants from './constants'
import {Fields} from "../shared/modals";
import { BillingPlanCode } from 'shared_server_client/types/billing_plan'
declare const AppleID: any
declare const FB: any
declare const unlayer: any
declare const RasaContentTool: any;
declare const DASHBOARD_ENV: string
declare const RASA_ONBOARDING_BASE_URL: string
const ONBOARDING_DUMMY_EMAIL = 'rasaiotest@gmail.com'

const noMoreUpgradePlans = [BillingPlanCode.ZOHO_PRO_2021, BillingPlanCode.ZOHO_PRO_2023, BillingPlanCode.ZOHO_PRO_ANNUAL_2023,
  BillingPlanCode.RASA_ENTERPRISE, BillingPlanCode.RASA_CONTACT_PRO]

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const falseValues = ['0', 'n', 'f', 'false', 'no']

export const isTruthy = (s: any): boolean => {
  if (isString(s)) {
    return !falseValues.includes(s.toLowerCase())
  } else {
    return Boolean(s)
  }
}

export const isTruthyNumeric = (s: any): number => {
  if (isString(s)) {
    return !falseValues.includes(s.toLowerCase()) ? 1 : 0
  } else {
    return Boolean(s) ? 1 : 0
  }
}

export const isFalsey = (s: any): boolean => !isTruthy(s)

const queryParam = (key: string): string => {
  const params = new URLSearchParams(window.location.search)
  return params.get(key)
}

export const isDevelopment = (): boolean => {
  return DASHBOARD_ENV === 'development' || queryParam('env') === 'development'
}

export const isVisible = (): boolean => {
  return false
}

export const validateEmail = (email: string): boolean => EMAIL_REGEX.test(email)

export const emailSeparator = (email: string): string => {
  return (email.indexOf(';') > 0) ? ';' :
  (email.indexOf(',') > 0) ? ',' : '';
}

const minPasswordLength = 12
const MaxPasswordLength = 32
const lengthErrorMessage = ` length must be ${minPasswordLength} to ${MaxPasswordLength} characters`
const missingCharactersMessage = ' must contain at least'

export const validatePassword = (password: string): string => {
  if (password) {
    const containsUpperCaseAlphabet = /[A-Z]/.test(password)
    const containsLowerCaseAlphabet = /[a-z]/.test(password)
    const containsNumber = /\d/.test(password)
    const isShortPassword = password.length < minPasswordLength || password.length > MaxPasswordLength

    let message = ''
    if (isShortPassword || !containsUpperCaseAlphabet || !containsLowerCaseAlphabet || !containsNumber) {
      message = 'Password'
    }

    if (isShortPassword) {
      message += lengthErrorMessage
    }

    if (!containsUpperCaseAlphabet || !containsLowerCaseAlphabet || !containsNumber) {
      message += isShortPassword ? `,${missingCharactersMessage}` : missingCharactersMessage
    }

    if (!containsUpperCaseAlphabet) {
      message += ' one uppercase letter'
    }

    if (!containsLowerCaseAlphabet) {
      message += message.indexOf('one') > -1 ?
        ',' : ''
      message +=  ' one lowercase letter'
    }

    if (!containsNumber) {
      message += message.indexOf('one') > -1 ?
        ',' : ''
      message += ' one number'
    }

    // replacing last comma (,) with and in message string if found
    const lastCommaIndex = message.lastIndexOf(',')
    if (lastCommaIndex !== -1) {
      message = message.substring(0, lastCommaIndex) + ' and' + message.substring(lastCommaIndex + 1)
    }

    return message
  } else {
    return null
  }
}

export const isPasswordValidationFailedError = (errorMessage: string): boolean => {
  const searchTerms = [lengthErrorMessage, missingCharactersMessage];
  const pattern = new RegExp("\\b(" + searchTerms.join("|") + ")\\b");

  return pattern.test(errorMessage)
}

const DOMAIN_REGEX = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateDomain = (domain: string): boolean => {
  return DOMAIN_REGEX.test(domain)
 }

export const isFacebookLoaded = (): boolean => {
  try {
    if (FB !== null) {
      return true
    }
  } catch (error) {
    // ignore
  }
  return false
}
export const isAppleLoaded = (): boolean => {
  try {
    if (AppleID !== null) {
      return true
    }
  } catch (error) {
    // ignore
  }
  return false
}

export const isUnlayerLoaded = (): boolean => {
  try {
    if (unlayer) {
      return true
    }
  } catch (error) {
    // ignore
  }
  return false
}

export const isRasaContentToolLoaded = (): boolean => {
  try {
    if (RasaContentTool !== null) {
      return true
    }
  } catch (error) {
    // ignore
  }
  return false
}

export const getEnvironmentSuffix = () => {
  if (DASHBOARD_ENV === 'development') {
    return '-dev'
  }
  return ''
}

export const copyToClipboard = (text) => window.navigator.clipboard.writeText(text)

export const downloadEncodedFile = (content: string, fileType: string, filename?: string) => {
  const blob = new Blob([Buffer.from(content, 'base64')], { type: blobType(fileType)});
  return downloadBlobfile(blob, fileType, filename)
}

export const downloadTextFile = (content: string, fileType: string, filename?: string) => {
  const blob = new Blob([content], { type: blobType(fileType)});
  return downloadBlobfile(blob, fileType, filename)
}

const blobType = (fileType: string) => {
  if (fileType.toLowerCase() === 'pdf') {
    return `application/pdf;charset=utf-8;`
  } else {
    return `text/${fileType};charset=utf-8;`
  }
}

const downloadBlobfile = (blob: Blob, fileType: string, filename: string) => {
  const outputFilename = filename || `download.${fileType}`

  if ((navigator as Fields).msSaveBlob) {
    // IE 10+
    (navigator as Fields).msSaveBlob(blob, outputFilename)
  } else {
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', outputFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  return {type: 'downloaded'};
}

export const addNewsletterUrl = (queryParams: any = {}) => {
  const fullParams = {
    ...queryParams,
    email: ONBOARDING_DUMMY_EMAIL,
    rasa_token: window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN),
  }
  const queryString = Object.keys(fullParams)
                        .map((k: any) => `${k}=${encodeURIComponent(fullParams[k])}`)
                        .join('&')
  return `${RASA_ONBOARDING_BASE_URL}?${queryString}`
}

export const isNewsletterOwner = (person, activeCommunity) => {
  return person.userId === activeCommunity.data.owner_user_id
}

export const isAccountOwner = (person, activeCommunity) => {
  return person.userId === activeCommunity.data.account_owner_user_id
}

export const logout = () => {
  window.localStorage.removeItem(AuthConstants.RASA_AUTH_TOKEN)
  window.location.replace(LOGOUT_BASE_ROUTE)
}

export const stripHTML = (html) => {
   const tmpDiv = document.createElement('DIV');
   tmpDiv.innerHTML = html;
   return tmpDiv.textContent || tmpDiv.innerText || '';
}

export const updateContactStatus = (record: any, uploadType: string) => {
  switch (uploadType) {
    case Constants.UNSUBSCRIBE:
      return {
        email: record.email,
        is_subscribed: 0,
        unsubscribe_reason: 'CSV',
      }
    case Constants.ARCHIVE:
      return {
        email: record.email,
        is_archived: 1,
      }
    default:
      return record
  }
}

export const tryParseJson = (json: string) => {
  try {
    return JSON.parse(json)
  } catch {
    return {}
  }
}

export enum SIGNUP_STEPS {
  ACCOUNT = 'ACCOUNT',
  CANSPAM = 'CANSPAM',
  BASE = 'BASE',
  BILLING = 'BILLING',
  COMPLETION = 'COMPLETION',
  EMAIL_PREFERENCES = 'EMAIL_PREFERENCES',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export const noMoreUpgradeAvailable = (currentPlan: any) => {
  return currentPlan.code_monthly && noMoreUpgradePlans.includes(currentPlan.code_monthly)
}

export const tryParseJSON = (jsonString: string) => {
  try {
    return jsonString ? JSON.parse(jsonString) : new Object()
  } catch (e) {
    return new Object()
  }
}

import { AggregationType } from './constants'
export * from './constants'

export interface AggregationRequest {
  child?: AggregationRequest,
  childs?: AggregationRequest[],
  extra?: {
    [key: string]: string,
  },
  field: string,
  name: string,
  type: AggregationType,
  unique_on?: string,
}

export interface ResponseAggregateUnique {
  value: number,
}

export interface ResponseAggregate {
  child?: ResponseAggregation,
  doc_count: number,
  key: string,
  unique?: ResponseAggregateUnique,
  top_community_person_ids?: ResponseAggregation,
}

export interface ResponseAggregation {
  buckets: ResponseAggregate[],
}

export interface ResponseAggregations {
  [key: string]: ResponseAggregation
}

export interface ResponseHit {
  _id: string,
  _index: string,
  _source: any,
}

export interface ResponseMatches {
  hits: ResponseHit[],
  max_score: number,
  total: number,
}

export interface ResponsePayload {
  aggregations?: ResponseAggregations,
  hits?: ResponseMatches,
}

import { AjaxWrapper, HttpMethod } from './ajaxWrapper'

// This class defines the base functionality for an Entity client that knows how
// to interact with the server to handle basic CRUD operations. Some entities
// only support read operations, but others will support all

// TODO: DataType can be either a dictionary or an array.
type DataType = any

export type RecordIdType = string | number

export class BaseClientEntity {
  public readonly name: string

  protected _data: DataType = {}
  private _id: RecordIdType = null
  private _ids: string = null
  private _communityId: RecordIdType = null

  constructor(name: string) {
    this.name = name
  }

  get data(): DataType {
    return this._data;
  }

  public newRecord() {
    this._data = {} // wipe out the data
    this._id = null
    this._ids = null
    this._communityId = null
  }

  // this creates a new record but sets the ID so that when
  // we send to server we only send values originating from the client
  public setRecordId(communityId: RecordIdType, id: RecordIdType) {
    this.newRecord();
    this._id = id
    this._communityId = communityId
    this._data.id = id
  }

  public setBulkIds(communityId: RecordIdType, ids: string) {
    this.newRecord();
    this._ids = ids
    this._communityId = communityId
    this._data.ids = ids
  }

  public save() {
    const url: string = this.getServerUrl() + this.getSaveUrl(this._communityId, this._id, this._ids)
    if (this._id) {
      return AjaxWrapper.ajax(url, HttpMethod.PUT, this.data)
                        .then((newData) => {
                          return this._loadFromServer(newData)
                        })
    } else {
      const data = {...this.data}
      delete data.id
      return AjaxWrapper.ajax(url, HttpMethod.POST, data)
                        .then((newData) => this._loadFromServer(newData))
    }
  }

  public archive() {
    const url: string = this.getServerUrl() + this.getSaveUrl(this._communityId, this._id, this._ids)
    if (this._id) {
      return AjaxWrapper.ajax(url, HttpMethod.DELETE, null)
                        .then(() => this._loadFromServer({is_active: false}))
    }
  }

  public upload(fileFormat: string, contents: any, options: any = {}) {
    const formData = new FormData()
    formData.append(fileFormat, contents)
    let url: string = this.getServerUrl() + this.getSaveUrl(this._communityId, this._id, this._ids)
    if (options && options.forceUpload) {
      url = `${url}?forceUpload=${options.forceUpload}`
    }
    const method: HttpMethod = this._id ? HttpMethod.PUT : HttpMethod.POST
    return AjaxWrapper.ajax(url, method, formData, null)
                      .then(() => this._loadFromServer({is_active: false}))
  }

  // load a record from server
  public load(communityId: string, idInput: RecordIdType) {
    const id = idInput ? idInput.toString() : null
    const url: string = this.getServerUrl() + this.getLoadUrl(communityId, id)
    return AjaxWrapper.ajax(
      url,
      HttpMethod.GET,
      null,
    ).then((newData) => {
      this._id = id
      this._communityId = communityId
      this._loadFromServer(newData)
    })
  }

  // override this method to change the URL for LOADING a particular entity type
  protected getLoadUrl(communityId: RecordIdType, id: RecordIdType) {
    return `/${this.getRootPath()}/${communityId}/${this.name}/${id}`
  }

  // override this method to change the URL for SAVING a particular entity type
  protected getSaveUrl(communityId: RecordIdType, id: RecordIdType, ids: string) {
    return `/${this.getRootPath()}/${communityId}/${this.name}${ id ? `/${id}` : ''}${ ids ? '/bulk' : ''}`
  }

  // override this method to change the Server URL for loading a particular entity type
  protected getServerUrl(): string {
    return AjaxWrapper.getServerUrl();
  }

  // override this method to change the loading functionality for the entity, by default, we simply put the data
  // into a member of the class called data
  protected _loadFromServer(data: any) {
    this._data = data;
    return data
  }

  private getRootPath = () => 'entity'

}

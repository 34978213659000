import {ajax} from 'rxjs/ajax'
import { Stats } from 'stats'
import * as Utils from './utility'
import { generateGuid } from 'utils'

declare const RASA_SELF_SERVICE_API: string
const SESSION_ID_KEY = "uiSessionId"
export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type AjaxStreamCallback = (success: boolean, chunk: string) => void

class _AjaxWrapper {
  private token: string = null

  public getServerUrl(): string {
    return RASA_SELF_SERVICE_API
  }

  public setToken(token: string): void {
    this.token = token
  }

  public async stream(
    url: string,
    method: HttpMethod,
    body: object,
    callback: AjaxStreamCallback,
    contentType: string = 'application/json',
  ) {

    const _stat = Stats.init()
    const AJAX_LOAD_STAT = this.getLoadStatKey(method, url)
    _stat.start(AJAX_LOAD_STAT)

    const headers = {
      'Authorization': `Bearer ${this.token}`,
      'rxjs-custom-header': 'Rxjs',
      'Accept': 'application/json',
      'x-dashboard-session-id': this.getCurrentSessionId(),
    }
    if ( contentType ) {
      headers['Content-Type'] = contentType
    }
    const response = await fetch(url, {
      body: JSON.stringify(body || {}),
      headers,
      method,
    })

    if ( response.status === 200 ) {
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
      while ( true ) {
        const result = await reader.read()
        if ( !result || result.done ) {
          callback(true, null)
          break
        } else {
          callback(true, result.value)
        }
      }
    } else {
      response.text().then((t) => callback(false, t))
    }

  }

  // override this method to change the default ajax implementation (which uses RxJs in the base class)
  public ajax(
    url: string,
    method: HttpMethod,
    body: object,
    contentType: string = 'application/json',
  ) {
    const _stat = Stats.init()
    const AJAX_LOAD_STAT = this.getLoadStatKey(method, url)
    _stat.start(AJAX_LOAD_STAT)

    const headers = {
      'Authorization': `Bearer ${this.token}`,
      'rxjs-custom-header': 'Rxjs',
      'x-dashboard-session-id': this.getCurrentSessionId(),
    }
    if ( contentType ) {
      headers['Content-Type'] = contentType
    }
    return ajax({
      body,
      headers,
      method,
      url,
    }).toPromise()
    .then(({response}) => {
      _stat.stopAndLog(AJAX_LOAD_STAT)
      return response
    })
    .catch((ex) => {
      if (ex.status === 401) {
        Utils.logout()
      }
      _stat.stopAndLog(AJAX_LOAD_STAT, 'error')
      throw ex // re-throw
    })
  }

  private getLoadStatKey(method, url) {
    return `${method}-${url}`
  }

  private getCurrentSessionId(): string {
    let sessionId = sessionStorage.getItem(SESSION_ID_KEY)
    if (!sessionId) {
      sessionId = generateGuid()
      sessionStorage.setItem(SESSION_ID_KEY, sessionId)
    }
    return sessionId
  }
}

export const AjaxWrapper = new _AjaxWrapper()
